import {useEffect} from 'react';

export const useUmami = () => {
  useEffect(() => {
    if (typeof window !== 'undefined' && process.env.NODE_ENV === 'production') {
      const script = document.createElement('script');
      script.src = 'https://umami.lezo.dev/script.js';
      script.setAttribute('data-website-id', 'c11ea8d9-51e8-48d5-ab1e-4f2546cec050');
      script.async = true;
      document.body.appendChild(script);
    }
  }, []);
};
