import {useCurrentStatus, useNow} from '@chaban/core';
import {LoaderFunctionArgs, MetaFunction, TypedResponse, json, redirect} from '@remix-run/node';
import {Links, Meta, Outlet, Scripts, ScrollRestoration, useLoaderData} from '@remix-run/react';
import {withSentry} from '@sentry/remix';
import dayjs from 'dayjs';
import {isbot} from 'isbot';
import {remixCaller} from './domain/api.server';
import {GTMConsent} from './domain/gtm/use-gtm';
import {sessionService} from './domain/session.server';
import {ThemeProvider} from './domain/theme';
import './globals.css';
import cookie from './hooks/cookie';
import {useUmami} from './hooks/use-umami';
import {useCrispChat} from './services/use-crisp';
import {Toaster} from './ui/sonner';

export const meta: MetaFunction<typeof loader> = ({data}) => {
  const [firstAlert, secondAlert] = data?.alerts?.map(({startAt, endAt, id, title}) => ({
    id,
    title,
    startAt: new Date(startAt),
    endAt: new Date(endAt),
  })) ?? [null, null];

  const encodedTitle = decodeURI('Pont Chaban Delmas : horaires, levées et fermetures à venir');
  const encodedDescription = decodeURI(
    firstAlert && secondAlert
      ? `Suivez les horaires d'ouverture et de fermeture du pont Chaban Delmas de Bordeaux.
Ne manquez pas les prochaines dates de levées, telles que ${firstAlert.title.toLowerCase()} le ${dayjs(
          firstAlert.startAt,
        ).format('DD/MM/YYYY')} de ${dayjs(firstAlert.startAt).hour()}h${dayjs(firstAlert.startAt).format(
          'mm',
        )} à ${dayjs(firstAlert.endAt).hour()}h${dayjs(firstAlert.endAt).format(
          'mm',
        )} et ${secondAlert.title.toLowerCase()} le ${dayjs(firstAlert.startAt).format(
          'DD/MM/YYYY',
        )} de ${dayjs(secondAlert.startAt).hour()}h${dayjs(secondAlert.startAt).format(
          'mm',
        )} à ${dayjs(secondAlert.endAt).hour()}h${dayjs(secondAlert.endAt).format('mm')}.`
      : "Découvrez les horaires d'ouverture et de fermeture du pont Chaban-Delmas de Bordeaux, ainsi que les prochaines dates de levées. Consultez notre page pour savoir si le pont est ouvert ou fermé aujourd'hui. Ne manquez pas les prochaines fermetures.",
  );
  return [
    {title: encodedTitle},
    {name: 'description', content: encodedDescription},
    {name: 'viewport', content: 'width=device-width,initial-scale=1'},
    {charset: 'utf-8'},
    {name: 'apple-itunes-app', content: 'app-id=6448217836'},
    {name: 'google-adsense-account', content: 'ca-pub-4102217073383903'},
    {name: 'image', content: 'https://lezo-files.s3.fr-par.scw.cloud/chaban-blog/chaban-opened.webp'},
  ];
};
export function links() {
  return [
    {
      rel: 'alternate icon',
      type: 'image/png',
      href: '/favicons/favicon-32x32.png',
    },
    {rel: 'mask-icon', href: '/favicons/mask-icon.svg'},
    {rel: 'apple-touch-icon', href: '/favicons/apple-touch-icon.png'},
    {rel: 'icon', type: 'image/svg+xml', href: '/favicons/favicon.svg'},
    {
      rel: 'preload',
      href: 'https://cdn.ampproject.org/v0/amp-ad-0.1.js',
      as: 'script',
      async: true,
      'custom-element': 'amp-ad',
    },
    {
      rel: 'preload',
      href: 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-4102217073383903',
      as: 'script',
      async: true,
      crossOrigin: 'anonymous',
    },
  ].filter(Boolean);
}

export const loader = async (args: LoaderFunctionArgs) => {
  const domain = args.request.headers.get('host') || '';
  if (domain === 'pont-chaban-delmas.com') {
    const urlWithoutHost = args.request.url.split('/').slice(3);
    throw redirect(`https://www.pont-chaban-delmas.com/${urlWithoutHost.join('/')}`, {status: 301});
  }
  const packages = await import('../package.json');
  const caller = await remixCaller(args.request.headers);
  const reports = await caller.alert.getReports({});
  const alerts = await caller.alert.getAlerts({
    channelIds: [],
    minDate: new Date(),
  });
  const isBot = isbot(args.request.headers.get('user-agent'));
  const consent: GTMConsent | undefined = isBot
    ? {
        ad_personalization: 'denied',
        ad_storage: 'denied',
        ad_user_data: 'denied',
        analytics_storage: 'denied',
      }
    : (await sessionService.consent.get(args.request.headers)) || undefined;

  const result = {
    ENV: {VERSION: packages.version as string},
    data: (cookie.node.get(args.request.headers.get('Cookie'), 'theme') || 'light') as 'light' | 'dark',
    consent,
    alerts,
    reports,
  };

  return json(result, {status: 200});
};

type UnTypedResponse<R> = R extends TypedResponse<infer U> ? U : never;

export type RootLoaderData = UnTypedResponse<Awaited<ReturnType<typeof loader>>>;
function App() {
  const {data: themeData, alerts, consent, reports} = useLoaderData<RootLoaderData>();
  const now = useNow();
  useCrispChat();
  const futureAlerts = alerts.filter(a => new Date(a.endAt) > now);
  const nextAlert = futureAlerts[0];
  const status = nextAlert ? useCurrentStatus(new Date(nextAlert.startAt), new Date(nextAlert.endAt)) : 'OPEN';
  useUmami();
  return (
    <html lang="fr">
      <head>
        <Meta />
        <Links />
        <script dangerouslySetInnerHTML={{__html: tagManagerContent}} />
        <script async custom-element="amp-ad" src="https://cdn.ampproject.org/v0/amp-ad-0.1.js" />
        <script
          async
          src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-4102217073383903"
          crossOrigin="anonymous"
        />
      </head>
      <body>
        <noscript>
          <iframe
            src="https://www.googletagmanager.com/ns.html?id=GTM-KZ938RFF"
            height="0"
            width="0"
            style={{display: 'none', visibility: 'hidden'}}></iframe>
        </noscript>
        <ThemeProvider
          isAuthed={!!reports.userId}
          isNotRaiseCount={reports.isNotRaiseCount}
          isRaiseCount={reports.isRaiseCount}
          defaultTheme={themeData}
          currentStatus={status}
          alerts={futureAlerts.map(a => ({
            ...a,
            startAt: new Date(a.startAt),
            endAt: new Date(a.endAt),
          }))}>
          <Outlet />
        </ThemeProvider>
        <ScrollRestoration />
        <Scripts />
        <Toaster theme={themeData} position="bottom-center" />
      </body>
    </html>
  );
}

export default withSentry(App);

const tagManagerContent = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-KZ938RFF');`;
