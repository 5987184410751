import {useInterval} from '@chaban/core';
import {Crisp} from 'crisp-sdk-web';
import {useEffect, useState} from 'react';

const CRISP_WEBSITE_ID = '024c104e-3a01-440a-8149-e1493080c5de';

export const useCrispChat = () => {
  useEffect(() => {
    if (process.env.NODE_ENV !== 'development') {
      Crisp.setSafeMode(true);
      Crisp.configure(CRISP_WEBSITE_ID);
    }
  }, []);
};

export const useCrispSyncUser = (user: {email?: string; name?: string}) => {
  const [isInitialized, setIsInitialized] = useState(false);
  useInterval(
    () => {
      if (isInitialized || !user.email || !user.name || process.env.NODE_ENV === 'development') {
        return;
      }

      if (!Crisp.isCrispInjected()) {
        return;
      }
      if (Crisp.user.getEmail() !== user.email || Crisp.user.getNickname() !== user.name) {
        Crisp.user.setEmail(user.email);
        Crisp.user.setNickname(user.name);
      }

      setIsInitialized(true);
    },
    isInitialized || !user.email || !user.name ? null : 1000,
  );
};
